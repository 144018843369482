import React from 'react';
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import favicon from '../images/fav2.png';

const SEO = ({title}) => {

    const getSiteData = graphql`
        query{
            site{
                siteMetadata {
                    title
                }
            }
        }
    `
    const data = useStaticQuery(getSiteData)

    return (
        <Helmet htmlAttributes={{
            lang: 'fr',
          }}        
                title={`${data.site.siteMetadata.title} | ${title} `}
                link={[{rel: 'shortcut icon', type: 'image/png', href: `${favicon}`}]}
                
        >
          
        </Helmet>

            
    );
};

export default SEO;